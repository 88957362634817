<template>
    <v-app>
        <v-app-bar
            id="vAppBar"
            :color="appBarColor"
            class="border-b"
            elevation="1"
            extension-height="51"
            tile
        >
            <template #prepend>
                <v-btn
                    v-if="iam"
                    :icon="navigationDrawer ? '$close' : '$menu'"
                    @click="navigationDrawer = !navigationDrawer"
                />
            </template>

            <v-toolbar-title>
                <portal-target name="toolbar-title">
                    {{ title }}
                </portal-target>
            </v-toolbar-title>

            <template #append>
                <portal-target name="commands">
                    <v-menu
                        v-if="showConnector"
                        transition="slide-y-transition"
                        width="280"
                    >
                        <template #activator="{ props: menuProps }">
                            <v-btn
                                icon="$dotsVertical"
                                v-bind="menuProps"
                                variant="plain"
                            />
                        </template>

                        <v-list>
                            <connector/>
                        </v-list>
                    </v-menu>
                    <v-btn
                        v-else-if="!hasAssigments"
                        :loading="loading"
                        icon="$refresh"
                        @click="getAssignments"
                    />
                </portal-target>
            </template>

            <template
                v-if="bannerActive"
                v-slot:extension
            >
                <v-alert
                    v-if="banner"
                    :color="appBarColor"
                    :icon="bannerIcon"
                    :text="banner"
                    border="bottom"
                    density="compact"
                    style="font-size: 0.90em"
                    tile
                    type="warning"
                />
            </template>
        </v-app-bar>

        <v-navigation-drawer
            v-model="navigationDrawer"
            border="0"
            location="top"
            temporary
        >
            <template #prepend>
                <portal-target
                    name="assignment-info"
                />
            </template>

            <portal-target
                name="related-assignments"
            />

            <template #append>
                <v-row
                    class="bg-grey-darken-4 pa-4"
                    dense
                    no-gutters
                >
                    <v-col
                        class="text-left"
                        cols="3"
                    >
                        <licenses
                            :toolbar-color="appBarColor"
                        />
                    </v-col>

                    <v-col
                        align-self="center"
                        class="grey--text text-center"
                        cols="6"
                        style="font-size: xx-small"
                    >
                        <div class="font-weight-bold text-grey">
                            &copy; <span v-html="copyYear"/> CC BY-NC-ND 4.0 International
                        </div>
                        <div class="font-italic text-grey-darken-1">
                            {{ title }} v.{{ version }} API v.{{ apiVersion }}
                        </div>
                    </v-col>
                    <v-col
                        class="text-right"
                        cols="3"
                    >
                        <connector
                            v-if="!showConnector"
                            dense
                        />
                    </v-col>
                </v-row>
            </template>
        </v-navigation-drawer>

        <v-main class="background">
            <router-view/>
        </v-main>
    </v-app>
</template>

<script lang="ts" setup>
import { AssignmentsApi } from "@/@api/AssignmentsApi";
import { colors } from "@/plugins/vuetify";
import { useAppStore, usePublisherStore, storeToRefs } from "@/stores";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";

let copyYear = String(new Date().getFullYear())
if (copyYear !== "2024") {
    copyYear = `2024&minus;${copyYear}`
}
const title = import.meta.env.VITE_NAME
const version = import.meta.env.VERSION

const AppStore = useAppStore()
const {
    setAppOnline,
    setStandalone,
} = AppStore
const {
    apiVersion,
    appOnline,
    standalone,
} = storeToRefs(AppStore)

const PublisherStore = usePublisherStore()
const {setAssignments} = PublisherStore
const {
    assignment,
    assignments,
    iam,
} = storeToRefs(PublisherStore)

const appBarColor = ref<string>("#ffffff")

const banner = ref<string | null>(null)
const bannerActive = computed(() => !!banner.value)
const bannerIcon = ref<string>('$calendarClock')

const showConnector = computed(() => {
    if (import.meta.env.DEV) {
        return !iam.value
    }

    return ( standalone.value && !iam.value )
})

const hasAssigments = computed(() => assignments.value.length > 0)

const loading = ref(false)

const navigationDrawer = ref(false)

onMounted(async () => {
    setStandalone(window.matchMedia('(display-mode: standalone)').matches ||
        ( ( window.navigator as any ).standalone === true ))

    window.addEventListener('online', () => setAppOnline(true))
    window.addEventListener('offline', () => setAppOnline(false))

    appBarColor.value = "#ffffff"
})

onBeforeUnmount(() => {
    window.removeEventListener('online', () => setAppOnline(true))
    window.removeEventListener('offline', () => setAppOnline(false))
})

watch(appBarColor, appBarColor => {
    const meta = document.querySelector<HTMLMetaElement>('meta[name=theme-color]')

    if (meta) {
        meta.content = appBarColor.startsWith('#') ? appBarColor : `#${appBarColor}`
    }
})

watch([appOnline, assignment], ([appOnline, assignment]) => {
    if (!appOnline) {
        appBarColor.value = colors.red.darken3
        bannerIcon.value = "$wifiOff"
        banner.value = "Non riesco a collegarmi a Internet"
    } else if (assignment && ( assignment.expirationDays !== undefined )) {
        appBarColor.value = colors.orange.darken2
        bannerIcon.value = "$calendarClock"

        if (assignment.expirationDays === 0) {
            banner.value = "L'assegnazione è scaduta"
        } else if (assignment.expirationDays === 1) {
            banner.value = "L'assegnazione scade domani"
        } else {
            banner.value = `Assegnazione in scadenza tra ${assignment.expirationDays} giorni`
        }
    } else {
        appBarColor.value = "#ffffff"
        banner.value = null
    }
})

async function getAssignments() {
    loading.value = true

    setAssignments(await AssignmentsApi.list())

    loading.value = false
}
</script>

<style scoped lang="scss">
.background {
    background-color: #fff;
    background-image: url("@/assets/images/235861.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50%;
    transition: background-color 250ms ease-in;
}
</style>
